import React, { useEffect, useState } from "react";
import { Link, Outlet } from "react-router-dom";

import { ErrorBoundary } from "./ErrorBoundary";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { LamfoApiUserRepository } from "../../infrastructure/LamfoApiUserRepository";
import { LamfoApiUserDataResponse } from "../../infrastructure/LamfoApiResponses";
import { Image, NavDropdown } from "react-bootstrap";
import styles from "./layout.module.scss";
import UserContext from "../../contexts/UserContext";

const reporitory = new LamfoApiUserRepository();

export function Layout() {
	const [userDataResponse, setUserDataResponse] = useState<LamfoApiUserDataResponse>();

	useEffect(() => {
		reporitory
			.getUserData()
			.then((userDataResponse) => {
				setUserDataResponse(userDataResponse);
			})
			.catch((error) => {
				console.log(error);
			});
	}, [reporitory]);

	return (
		<React.Fragment>
			<Navbar bg="primary" variant="dark" expand="lg" sticky="top">
				<Container>
					<Navbar.Brand href={process.env.REACT_APP_LAMFO_OLD_URL}>
						<img
							alt=""
							src="/img/logo.svg"
							width="30"
							height="30"
							className="d-inline-block align-top"
						/>
						<span className="navbartitle m-1">LAMFO</span>
					</Navbar.Brand>
					<Navbar.Toggle aria-controls="basic-navbar-nav" />
					<Navbar.Collapse id="basic-navbar-nav">
						<Nav className="me-auto">
							{ (!userDataResponse || !userDataResponse.success)
								?
								<Nav.Link href={process.env.REACT_APP_LAMFO_OLD_URL + 'loginreq.php'}>Login</Nav.Link>
								:
								''
							}
							<Link to="/team" className="nav-link">Planteles</Link>
							<NavDropdown title="Mercado" id="mercado-nav-dropdown">
								{ (userDataResponse && userDataResponse.success)
									? 
									<NavDropdown.Item
										href={process.env.REACT_APP_LAMFO_OLD_URL + 'mimercado.php'}
									>
										Mi mercado
									</NavDropdown.Item>
									: ''
								}
								<NavDropdown.Item
									href={process.env.REACT_APP_LAMFO_OLD_URL + 'transferencias.php'}
								>
									Traspasos
								</NavDropdown.Item>
								<NavDropdown.Item
									href={process.env.REACT_APP_LAMFO_OLD_URL + 'exterior.php'}
								>
									Exterior
								</NavDropdown.Item>
								<NavDropdown.Item
									href={process.env.REACT_APP_LAMFO_OLD_URL + 'subastas.php'}
								>
									Subastas
								</NavDropdown.Item>
                				<Link to="/free-players" className="dropdown-item">Libres</Link>
            				</NavDropdown>
							<Link to="/economy" className="nav-link">Economía</Link>
							<Link to="/review" className="nav-link">Revisión</Link>
							<Link to="/rules" className="nav-link">Reglamento</Link>
						</Nav>
					</Navbar.Collapse>
				</Container>
				{ (userDataResponse && userDataResponse.success) ?
					<Container>
						<div className={styles.loggedteamdata}>
							<Link to={'/team/' + userDataResponse.team.code}>
								<Image
									src={userDataResponse.team.shield}
									className={styles.loggedshield}
								/>
								{userDataResponse.team.name}
							</Link>
						</div>
					</Container>
					:
					''
				}
			</Navbar>
			<ErrorBoundary>
				<UserContext.Provider value={userDataResponse ?? {} as LamfoApiUserDataResponse}>
					<Container fluid className={styles.appcontainer}>
						<Outlet />
					</Container>
				</UserContext.Provider>
			</ErrorBoundary>
		</React.Fragment>
	);
}
