import React, { useEffect, useState } from "react";

import { LamfoApiTeamsListResponse } from "../../infrastructure/LamfoApiResponses";
import { LamfoApiTeamRepository } from "../../infrastructure/LamfoApiTeamRepository";
import { Form } from "react-bootstrap";
import { Loader } from "../loader/Loader";

const reporitory = new LamfoApiTeamRepository();

export function TeamSelect(props: { 
    onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void,
    division: string
}) {
    const { onChange, division } = props;
    
    const [teamCode, setTeamCode] = useState<string>('');
    const [teamListResponse, setTeamListResponse] = useState<LamfoApiTeamsListResponse>();
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
		setIsLoading(true);
		reporitory
			.getTeamsList(division)
			.then((teamsListResponse) => {
				setTeamListResponse(teamsListResponse);
				setIsLoading(false);
			})
			.catch((error) => {
				console.log(error);
			});
	}, [reporitory, division]);

    const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setTeamCode(event.target.value)
        onChange(event);
    }

    if (isLoading) {
		return (
			<React.Fragment>
				<Loader />
			</React.Fragment>
		);
	}

    return (
        <React.Fragment>
            <Form.Select
				className="mt-3 mb-3"
                value={teamCode}
				onChange={e => handleSelectChange(e)}
				aria-label="review-team-select"
			>
				<option>Seleccione equipo</option>
				{
                    teamListResponse?.divisions && teamListResponse?.divisions.length == 1 ? (
                        teamListResponse?.divisions.map((division, divisionIndex) => (
                            division.teams.map((team, teamIndex) => (
                                <option key={teamIndex} value={team.code}>{team.name}</option>
                            ))
                        ))
                    ) : (
                        teamListResponse?.divisions.map((division, divisionIndex) => (
                            <optgroup key={divisionIndex} label={division.name}>
                                {division.teams.map((team, teamIndex) => (
                                    <option key={teamIndex} value={team.code}>{team.name}</option>
                                ))}
                            </optgroup>
                        ))
                    )
                }
			</Form.Select>
        </React.Fragment>
    );
}