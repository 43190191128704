import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Home } from "./sections/home/Home";

import { Layout } from "./sections/layout/Layout";
import { Review } from "./sections/review/Review";
import { Team } from "./sections/team/Team";
import { TeamDetail } from "./sections/team/TeamDetail";
import { Match } from "./sections/match/Match";
import { FreePlayers } from "./sections/freePlayers/FreePlayers";
import { Economy } from "./sections/economy/Economy";
import { Registration } from "./sections/registration/Registration";
import { Regulation } from "./sections/regulation/Regulation";

const router = createBrowserRouter([
    {
        path: "/",
        element: <Layout />,
        children: [
            {
                path: "/",
                element: <Home />,
            },
            {
                path: "/match/:matchId",
                element: <Match />,
            },
            {
                path: "/review",
                element: <Review />,
            },
            {
                path: "/rules",
                element: <Regulation />,
            },
            {
                path: "/team",
                element: <Team />,
            },
            {
                path: "/team/:teamCode",
                element: <TeamDetail />,
            },
            {
                path: "/free-players",
                element: <FreePlayers />,
            },
            {
                path: "/economy",
                element: <Economy />,
            },
            {
                path: "/registration",
                element: <Registration />,
            },
        ]
    },
]);

export function Router() {
    return <RouterProvider router={router} />;
}