import {
	Team,
	LamfoApiUserDataResponse
} from "./LamfoApiResponses";

export class LamfoApiUserRepository {
	private readonly apiUrl = process.env.REACT_APP_LAMFO_API_URL;
	private readonly getUserDataEndpoint = "$apiUrl/private/user/logged-user-data";

	async getUserData(): Promise<LamfoApiUserDataResponse> {
		const repositoryRequest = fetch(
			this.getUserDataEndpoint.replace("$apiUrl", this.apiUrl ?? ""),
			{ credentials: "include" }
		);

		return repositoryRequest
			.then((response) => response.json())
			.then((userData) => {
				return {
					success: userData.success as boolean,
					admin: userData.admin as boolean,
					team: userData.team as Team,
				};
			});
	}
}
