import React, { useEffect, useState } from "react";

import { LamfoApiTeamReviewResponse } from "../../infrastructure/LamfoApiResponses";
import { LamfoApiTeamRepository } from "../../infrastructure/LamfoApiTeamRepository";
import styles from "./review.module.scss";
import Image from 'react-bootstrap/Image';
import Card from 'react-bootstrap/Card';
import { Col, Row, Stack } from "react-bootstrap";
import Accordion from 'react-bootstrap/Accordion';
import Table from 'react-bootstrap/Table';
import { Loader } from "../../components/loader/Loader";
import { TeamSelect } from "../../components/teamSelect/TeamSelect";

const reporitory = new LamfoApiTeamRepository();

export function Review() {
	const [teamCode, setTeamCode] = useState<string>('');
	const [teamReviewResponse, setTeamReviewResponse] = useState<LamfoApiTeamReviewResponse>();
	const [isLoading, setIsLoading] = useState<boolean>(false);

	useEffect(() => {
		if (teamCode) {
			setIsLoading(true);
			reporitory
				.getTeamReview(teamCode)
				.then((teamReviewResponse) => {
					setTeamReviewResponse(teamReviewResponse);
					setIsLoading(false);
				})
				.catch((error) => {
					console.log(error);
				});
		}
	}, [reporitory, teamCode]);

	return (
		<React.Fragment>
			<h1 className="text-center mt-3">Revisión de medias</h1>
			<TeamSelect
				onChange={e => setTeamCode(e.target.value)}
				division="human"
			/>
			{isLoading && <Loader />}
			{!isLoading && teamReviewResponse?.reviews.map((review, index) => (
				<Card key={index} className="mb-3">
					<Card.Header className={styles.cardheader}>
						<Row>
							<Col className="col-3 p-0 text-center">
								<Image
									src={review.avatar ?? '/img/avatar.png'}
									roundedCircle
									className={styles.avatar}
									onError={({ currentTarget }) => {
										currentTarget.onerror = null;
										currentTarget.src="/img/avatar.png";
									}}
								/>
							</Col>
							<Col className="col-9 p-0">
								<div className={styles.playertext + ' ' + styles.playersurname}>
									{review.surname}
								</div>
								<div className={styles.playertext + ' ' + styles.playername}>
									{review.name}
								</div>
							</Col>
						</Row>			
					</Card.Header>
					<Card.Body>
						{review.real_team ?
							<Row className="mb-3">
								<Col className="col-3 p-0 text-center">
									<Image
										src={review.real_team_logo ?? '/img/shield.png'}
										className={styles.teamlogo}
										onError={({ currentTarget }) => {
											currentTarget.onerror = null;
											currentTarget.src="/img/shield.png";
										}}
									/>
									<Image
										src={review.real_team_country_flag ?? '/img/question.png'}
										roundedCircle
										alt={review.real_team_country_code}
										className={styles.teamflag}
										onError={({ currentTarget }) => {
											currentTarget.onerror = null;
											currentTarget.src="/img/question.png";
										}}
									/>
								</Col>
								<Col className="col-9 p-0">
									<div className={styles.playerteamtext + ' ' + styles.playerteam}>{
										review.real_team ?
											review.real_team : 
											'SIN DATOS'
									}</div>
									<div className={styles.playerteamtext + ' ' + styles.playerleague}>{review.real_team_competition}</div>
								</Col>
							</Row> :
							<Row className="mb-3">
								<Col className="p-0 text-center">
									Sin datos de equipo
								</Col>
							</Row>
						}
						
						<Row>
							<Accordion className="mt-3 p-1">
								<Accordion.Item eventKey="0">
									<Accordion.Header>Rendimiento medido</Accordion.Header>
									<Accordion.Body className="p-2">
										<Row>
											<Col className="p-0">
												<Stack>
													<div className={styles.statnumber}>{review.minutes}</div>
													<div className={styles.stattext}>Minutos</div>
												</Stack>
											</Col>
											<Col className="p-0">
												<Stack>
													<div className={styles.statnumber}>{review.goals}</div>
													<div className={styles.stattext}>Goles</div>
												</Stack>
											</Col>
											<Col className="p-0">
												<Stack>
													<div className={styles.statnumber}>{review.assists}</div>
													<div className={styles.stattext}>Asistencias</div>
												</Stack>
											</Col>
										</Row>
										<Row className="mt-2 mb-2">
											<Col className="p-0">
												<Stack>
													<div className={styles.statnumber}>{review.clean_sheets}</div>
													<div className={styles.stattext}>Valla inv</div>
												</Stack>
											</Col>
											<Col className="p-0">
												<Stack>
													<div className={styles.statnumber}>{review.yellow_cards}</div>
													<div className={styles.stattext}>Amarillas</div>
												</Stack>
											</Col>
											<Col className="p-0">
												<Stack>
													<div className={styles.statnumber}>{review.red_cards}</div>
													<div className={styles.stattext}>Rojas</div>
												</Stack>
											</Col>
										</Row>
										{review.competitions.length ?
											review.competitions.map((competition, index) => (
												<Table
													key={index}
													bordered
													size="sm"
													className={styles.statstable}
												>
													<thead>
														<tr>
															<th colSpan={6}>{competition.competition_name}</th>
														</tr>
														<tr>
															<th>
																<Image
																	src="/img/stopwatch-32.png"
																	alt="Minutos"
																	className={styles.statstableicon}
																/>
															</th>
															<th>
																<Image
																	src="/img/goal-post-32.png"
																	alt="Goles"
																	className={styles.statstableicon}
																/>
															</th>
															<th>
																<Image
																	src="/img/soccer-boots-32.png"
																	alt="Asistencias"
																	className={styles.statstableicon}
																/>
															</th>
															<th>
																<Image
																	src="/img/goalkeeper-32.png"
																	alt="Vallas invictas"
																	className={styles.statstableicon}
																/>
															</th>
															<th>
																<Image
																	src="/img/yellow-card-32.png"
																	alt="Amarillas"
																	className={styles.statstableicon}
																/>
															</th>
															<th>
																<Image
																	src="/img/red-card-32.png"
																	alt="Rojas"
																	className={styles.statstableicon}
																/>
															</th>
														</tr>
													</thead>
													<tbody>
														<tr>
															<td>{competition.minutes}</td>
															<td>{competition.goals}</td>
															<td>{competition.assists}</td>
															<td>{competition.clean_sheets}</td>
															<td>{competition.yellow_cards}</td>
															<td>{competition.red_cards}</td>
														</tr>
													</tbody>
												</Table>
											)) :
											<h5 className="text-center">No hay datos de competiciones</h5>
										}
									</Accordion.Body>
								</Accordion.Item>
							</Accordion>
						</Row>
					</Card.Body>
					<Card.Footer>
						<Row>
							<Col>
								<div className={styles.skillvalue + ' ' + styles.skillvalueold}>{review.current_skill}</div>
							</Col>
							<Col className="text-center">
								<Image
									src={ 'img/diagonal-arrow-' + (review.new_skill > review.current_skill ? 'up' : 'down') + '.png' }
									className={styles.arrow}
								/>	
							</Col>
							<Col>
								<div className={styles.skillvalue + ' ' + styles.skillvaluenew}>{review.new_skill}</div>
							</Col>
						</Row>
					</Card.Footer>
				</Card>
			))}
		</React.Fragment>
	);
}
