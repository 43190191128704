import {
	LamfoApiEmptyResponse
} from "./LamfoApiResponses";

export class LamfoApiPlayerRepository {
	private readonly apiUrl = process.env.REACT_APP_LAMFO_API_URL;
	private readonly transferPriceEndpoint = "$apiUrl/private/player/transfer-price";

	async setTransferPrice(playerCode: string, price: number): Promise<LamfoApiEmptyResponse> {
		const repositoryRequest = fetch(
			this.transferPriceEndpoint.replace("$apiUrl", this.apiUrl ?? ""),
			{
				method: "PUT",
				credentials: "include",
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({ playerCode: playerCode, price: price }),
			}
		);

		return repositoryRequest
			.then((response) => response);
	}

	async deleteTransferPrice(playerCode: string): Promise<LamfoApiEmptyResponse> {
		const repositoryRequest = fetch(
			this.transferPriceEndpoint.replace("$apiUrl", this.apiUrl ?? ""),
			{
				method: "DELETE",
				credentials: "include",
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({ playerCode: playerCode }),
			}
		);

		return repositoryRequest
			.then((response) => response);
	}
}
